body {
  background-color: #f2ce21;
  /* font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif */
}

#contentDiv {
  width: 100vw;
  min-height: 100vh;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2ce21;
  color: #000000;
}

#hoverHeader {
  font-size: 18px;
}

.work-description {
  margin-left: 20px;
  text-align: left;
}

.web-p {
  font-size: 14px;
}

.work-title {
  margin-left: 20px;
  text-align: left;
  font-weight: 600;
}

.inline-link {
  text-decoration: underline;
  font-weight: bolder;
  color: black;
}

.box-description {
  margin-top: 10px;
}

#hoverHeader:hover {
  filter: invert(100%);
  /* mix-blend-mode:difference; */
}

#hoverHeader h{
  font-size: 100px;
}

#subTitle {
  font-size: 14px;
  font-weight: bolder;
}

#paragraph {
  font-size: 12px;
  text-align: center;
}

#workTitle {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  /* border: 1px solid black; */
  /* height: 150px;
  width: 150px */
}

#workThumbnail:hover {
  filter: invert(100%);
  mix-blend-mode: multiply;
}

.work-section::-webkit-scrollbar {
  display: none;
}